.HomeScreenButtonHelp {
  color: #fff;
  letter-spacing: .28px;
  border: 1px solid #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 36px;
  padding: 10px 6px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.HomeScreenButtonCreateListing {
  background: var(--app-gold-yellow, #f4c542);
  max-width: 300px;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .4px;
  border-radius: 12px;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  overflow: hidden;
}

@media (width <= 390px) {
  .HomeScreenButtonCreateListing {
    border-radius: 8px;
    height: 100%;
    padding: 4px;
    font-size: 16px;
  }
}

.SearchUicCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  display: flex;
}

.SearchUicCon2 {
  align-self: stretch;
  align-items: flex-end;
  gap: 6px;
  display: flex;
}

.SearchUicSearchInput {
  border: .5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  color: var(--app-gray-medium, #6d6d6d);
  border-radius: 10px 0 0 10px;
  flex: 1 0 0;
  align-items: center;
  gap: 10px;
  padding: 4px 12px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.SearchUicBtnFilter {
  background: var(--app-white, #fff);
  color: var(--app-charcoal-dark, #2b2b2b);
  border-radius: 0 10px 10px 0;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 7px 12px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.SearchUicCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.SearchUicCategoryDescritionText {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .28px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SearchUicCon4 {
  flex-wrap: wrap;
  align-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 0 6px;
  display: flex;
}

.CategorySelect {
  background: var(--app-white, #fff);
  color: var(--app-gray-medium, #6d6d6d);
  border-radius: 6px;
  align-items: center;
  gap: 10px;
  padding: 6px 8px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
}

.CategorySelectActive {
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  color: var(--app-charcoal-dark, #2b2b2b);
  border-radius: 6px;
  align-items: center;
  gap: 10px;
  padding: 4px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
}
/*# sourceMappingURL=index.a307a02d.css.map */
