.HomeScreenButtonHelp {
  display: flex;
  height: 36px;
  padding: 10px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  border: 1px solid #fff;

  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.HomeScreenButtonCreateListing {
  max-width: 300px;
  display: flex;
  /* height: 36px; */
  padding: 6px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  overflow: hidden;

  border-radius: 12px;
  background: var(--app-gold-yellow, #f4c542);
  /* box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.5); */
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}
@media (max-width: 390px) {
  .HomeScreenButtonCreateListing {
    font-size: 16px; /* Reduce font size */
    padding: 4px 4px; /* Adjust padding */
    height: 100%;
    border-radius: 8px;
  }
}

.SearchUicCon1 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.SearchUicCon2 {
  display: flex;
  align-items: flex-end;
  gap: 6px;
  align-self: stretch;
}
.SearchUicSearchInput {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 10px 0px 0px 10px;
  border: 0.5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);

  flex: 1 0 0;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SearchUicBtnFilter {
  display: flex;
  padding: 7px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 10px 10px 0px;
  background: var(--app-white, #fff);

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SearchUicCon3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.SearchUicCategoryDescritionText {
  color: var(--app-charcoal-dark, #2b2b2b);

  /* app main label */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}
.SearchUicCon4 {
  display: flex;
  padding: 0px 6px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  align-self: stretch;
  flex-wrap: wrap;
}
.CategorySelect {
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--app-white, #fff);

  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.CategorySelectActive {
  display: inline-flex;
  padding: 4px 6px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
